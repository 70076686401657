import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/context";

const DashboardLinks = () => {
  const { handleClick } = useGlobalContext();

  return (
    <ul>
      <li onClick={handleClick}>
        <Link to='/dashboard'>Dashboard</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/dashboard/account'>Account</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/dashboard/deposit'>Deposit</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/dashboard/depositHistory'>Deposit History</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/dashboard/withdrawal'>Withdrawal</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/dashboard/withdrawalHistory'>Withdrawal History</Link>
      </li>
    </ul>
  );
};

export default DashboardLinks;
