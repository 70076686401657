import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='grid'>
        <div className='item'>
          <p>Take your crypto to the next level with DigitalCoinTrades.</p>
          <div className='btns'>
            <button type='button' className='blue'>
              <Link to='/sign-up'>Create account</Link>
            </button>
            <button type='button' className='grey'>
              <Link to='/sign-in'>Sign in</Link>
            </button>
          </div>
        </div>
        <div className='item'>
          <h4>Features</h4>
          <ul>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/guides'>Guides</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>Learn</h4>
          <ul>
            <li>
              <Link to='/guides'>Crypto Guides</Link>
            </li>
            <li>
              <Link to='/terms-of-use'>Terms of Use</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>About</h4>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/support-center'>Support Center</Link>
            </li>
            <li id='google_translate_element'></li>
          </ul>
        </div>
        <div className='item'>
          <h4>Address</h4>
          <ul>
            <li>
              <a
                href='https://goo.gl/maps/zcxnZm6wUSw35dvEA'
                target='_blank'
                rel='noreferrer'
              >
                Paradeplatz, 8001 Zürich, Switzerland
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright'>&copy; 2020 Digitalcointrades.net</div>
    </footer>
  );
};

export default Footer;
