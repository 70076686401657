import Spinner from "../../assets/spinner.svg";

const Loader = () => {
  return (
    <div className='loader'>
      <img src={Spinner} alt='spinner' className='spinner' />
    </div>
  );
};

export default Loader;
