import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

const Links = () => {
  const { handleClick } = useGlobalContext();

  return (
    <ul>
      <li onClick={handleClick}>
        <Link to='/'>home</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/about'>about</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/services'>services</Link>
      </li>
      <li onClick={handleClick}>
        <Link to='/support-center'>Support Center</Link>
      </li>
    </ul>
  );
};

export default Links;
