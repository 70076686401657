import { useGlobalContext } from '../../context/context';
import { useEffect, useState } from 'react';
import ButtonLoader from '../loaders/ButtonLoader';
import PasswordChanged from '../modals/PasswordChanged';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ForgotPassword = () => {
  const {
    setErrorMessage,
    errorMessage,
    setForgotPassword,
    setShowModal,
    showModal,
    baseURL,
    resetPage,
  } = useGlobalContext();

  const [resetError, setResetError] = useState(false);
  const [loading, setLoading] = useState(false);

  // FUNCTION TO RESET PASSWORD
  const resetPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/edit-password`, {
        method: 'PUT',
        body: JSON.stringify(formik.values),
        headers: {
          'Content-type': 'application/json',
        },
      });
      const data = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setShowModal(true);
        console.log(data);
      } else {
        setResetError(true);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ``,
      password: ``,
      seedPhrase: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),

      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('Password is required'),

      seedPhrase: Yup.string().required(`Seed Phrase is required`),
    }),
    onSubmit() {
      resetPassword();
    },
  });

  useEffect(() => {
    resetPage(`Reset Password`);
    // eslint-disable-next-line
  }, []);

  return (
    <section className='forgot-password'>
      <div className='form'>
        <h1>Reset Password</h1>
        <form onSubmit={formik.handleSubmit}>
          {/* SEED PHRASE INPUT */}
          <div className='form-control'>
            <label
              htmlFor='seed-phrase'
              className={
                formik.touched.seedPhrase && formik.errors.seedPhrase
                  ? `red`
                  : null
              }
            >
              {formik.touched.seedPhrase && formik.errors.seedPhrase
                ? formik.errors.seedPhrase
                : `Seed Phrase`}
            </label>
            <input
              type='text'
              placeholder='alpa-delta-beta-dog-omega-john'
              id='seed-phrase'
              className='input'
              name='seedPhrase'
              value={formik.values.seedPhrase}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* EMAIL INPUT */}
          <div className='form-control'>
            <label
              htmlFor='email'
              className={
                formik.touched.email && formik.errors.email ? `red` : null
              }
            >
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : `Email Address`}
            </label>
            <input
              type='email'
              placeholder='johnDoe@email.com'
              id='email'
              className='input'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* NEW PASSWORD INPUT */}
          <div className='form-control'>
            <label
              htmlFor='new-password'
              className={
                formik.touched.password && formik.errors.password ? `red` : null
              }
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : `New Password`}
            </label>
            <input
              type='password'
              placeholder='**********'
              id='new-password'
              className='input'
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* ERROR MESSAGE */}
          {resetError && (
            <div className={`error ${resetError && `show-error`}`}>
              <p>{errorMessage}</p>
            </div>
          )}

          {/* LOGIN BUTTON */}
          <div className='password-submitBtn'>
            <p onClick={() => setForgotPassword(false)}>Login instead</p>
            <div className='btn'>
              <button className='blue'>Reset Password</button>
              {loading && <ButtonLoader />}
            </div>
          </div>
        </form>
      </div>
      {showModal && <PasswordChanged />}
    </section>
  );
};

export default ForgotPassword;
