import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/context';
import VerifyAccount from '../../components/modals/VerifyAccount';
import Notifications from '../../components/modals/Notifications';

import {
  Ticker,
  MiniChartBTC,
  MiniChartETH,
  MiniChartLTC,
  AdvancedChart,
  Technical,
} from '../../components/trading-view/TickerWidgets';
import Wallet from '../../components/wallet/Wallet';
import Loader from '../../components/loaders/Loader';

const Dashboard = () => {
  const {
    setIsUser,
    fetchUser,
    setUserToken,
    userToken,
    loading,
    resetPage,
    userDetails,
  } = useGlobalContext();

  const [showNotification, setShowNotification] = useState(true);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // FETCH USER DETAILS
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userToken]);

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Dashboard`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='dashboard'>
        <Ticker />
        <div className='wallet-container'>
          {loading ? <Loader /> : <Wallet />}
        </div>
        <div className='mini-charts'>
          <MiniChartBTC />
          <MiniChartETH />
          <MiniChartLTC />
        </div>
        <div className='technical-chart'>
          <Technical />
        </div>
        <div className='advanced-chart'>
          <AdvancedChart />
        </div>
        {userDetails.verified === false && <VerifyAccount />}
        {userDetails.notification && showNotification && (
          <Notifications setShowNotification={setShowNotification} />
        )}
      </section>
    </>
  );
};

export default Dashboard;
