import Spinner from "../../assets/spinner.svg";

const ButtonLoader = () => {
  return (
    <div className='btn-loader'>
      <img src={Spinner} alt='spinner' />
    </div>
  );
};

export default ButtonLoader;
