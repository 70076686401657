import { useEffect } from "react";
import { useGlobalContext } from "../../context/context";

import {
  BankWithdrawal,
  CryptoWithdrawal,
} from "../../components/withdrawal/BankWithdrawal";

const Withdrawal = () => {
  const {
    setIsUser,
    resetPage,
    setUserToken,
    userToken,
    fetchUser,
    userDetails,
  } = useGlobalContext();

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
    resetPage(`Withdrawal`);
    // eslint-disable-next-line
  }, [setIsUser]);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // FETCH USER DETAILS
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userToken]);

  console.log(userDetails);

  return (
    <section className='withdrawal'>
      <BankWithdrawal />
      <CryptoWithdrawal />
    </section>
  );
};

export default Withdrawal;
