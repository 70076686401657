import { contacts } from '../../data/data';
import { useGlobalContext } from '../../context/context';
import { useEffect } from 'react';

const SupportCenter = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Contact Us`);
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <section className='contact'>
        <h2>Support Center</h2>
        <div className='methods'>
          {contacts.map((contact, index) => {
            const { logo, method, link } = contact;
            return (
              <div className='item' key={index}>
                <div className='image'>
                  <img src={logo} alt={method} fill className='img' />
                </div>
                <a href={link}>
                  <button className='grey'>{method}</button>
                </a>
              </div>
            );
          })}
        </div>
        <div className='form-container'>
          <h3>Leave a Message</h3>
          <form className='form'>
            <div className='form-control'>
              <label htmlFor='name' className='label'>
                Enter Your Name
              </label>
              <input
                type='text'
                placeholder='John Doe'
                id='name'
                className='input'
                name='name'
                // value={userLoginDetails.email}
                onChange={handleChange}
                // onKeyUp={() => validateEmail(`email`)}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='subject' className='label'>
                Enter Your Subject
              </label>
              <input
                type='text'
                placeholder='John Doe'
                id='subject'
                className='input'
                name='subject'
                // value={userLoginDetails.email}
                onChange={handleChange}
                // onKeyUp={() => validateEmail(`email`)}
                required
              />
            </div>
            <textarea name='' id='' placeholder='Enter Your Message'></textarea>

            <div className='submitBtn'>
              <button className='blue'>Submit</button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SupportCenter;
