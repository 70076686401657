import { investments } from '../../data/data';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';
import { useEffect } from 'react';

const Services = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Services`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='services'>
        <article className='header'>
          <h2>INVESTMENTS</h2>
          <h3>
            DigitalCoinTrades has various investment plans to fuel your finance
            and business.
          </h3>
          <p>
            DigitalCoinTrades is one of the longest running, most trusted and
            healthiest platforms. Our clients benefit from industry-leading
            cryptocurrency pairs, deep market access, and direct access to
            Principal OTC desks and futures exchanges. <br />
            <br />
            Through multiple international partners, customers enjoy fiat and
            cryptocurrency financing options with high monthly limits, including
            fiat financing in seven global currencies. For the most efficient
            transaction execution, we have extended the rate limit for clients
            trading algorithms using either REST or the WebSocket API.
          </p>
        </article>
        <div className='investments'>
          {investments.map((investment, index) => {
            const { name, logo, info } = investment;
            return (
              <div className='item' key={index}>
                <img src={logo} alt={name} />
                <div className='info'>
                  <h3>{name}</h3>
                  <p>{info}</p>
                </div>
              </div>
            );
          })}
        </div>
        <article className='client-support'>
          <div className='text'>
            <h2>CLIENT SUPPORT</h2>
            <h3>Expert support for professional & institutional traders</h3>
            <p>
              DigitalCoinTrades provides 24/7 access to a dedicated
              institutional relationship and account management team via email,
              phone, or any of these recommended encrypted messaging services..{' '}
              <br />
              <br /> Whether you&apos;re a family office, broker, high frequency
              dealer, or , we offer the most personalized service in the
              industry. DigitalCoinTrades personnel are assigned based on your
              specific needs based on , so you can find the best person to help
              you every time.
            </p>
          </div>
        </article>
        <article className='security'>
          <div className='text'>
            <h2>SECURITY</h2>
            <h3>DigitalCoinTrades sets the industry standard for security.</h3>
            <p>
              Security is at the heart of DigitalCoinTrades&apos; DNA, which is
              why it has an impeccable security record after more than a decade
              of business. As a cryptocurrency native organization,
              DigitalCoinTrades&apos; long-standing reputation for continues to
              set the industry standard with a focus on security through
              extensive controls designed to prevent theft of financial assets
              or information. <br />
              <br />
              As a result, clients have outsourced billions of dollars worth of
              on-chain assets to us, including ETH2 locked into staking, which
              is longer than any other peer exchange.
            </p>
          </div>
        </article>
        <article className='contact-box'>
          <p>
            DigitalCoinTrades&apos; organizational relationship and solutions
            team is made up of experts to meet all your needs in the
            ever-growing and complex world of cryptocurrencies.
          </p>
          <br />
          <br />
          <p>
            Contact us at <span>support@digitalcointrades.net</span> and someone
            from our team will reach directly
          </p>
          <div className='btn'>
            <Link to='/help-center'>
              <button className='blue'>Contact us</button>
            </Link>
          </div>
        </article>
      </section>
    </>
  );
};

export default Services;
